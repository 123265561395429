import React, { useState } from 'react';
import { Col, Modal, Row } from 'antd';

import swissmade from '../images/swiss-made.png';
import geneve from '../images/geneve.svg';
import drops from '../images/drops2.svg';

import Header from './Header';
import { motion } from 'framer-motion';

const Layout = ({ children }) => (
	<>
		<Header siteTitle="geneve.site"/>

		<main className="main">
			{children}
		</main>
	</>
);

export default Layout;
