import React, { useState } from 'react';

import { Modal, Button, Icon } from 'antd';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import LastPromo from '../components/LastPromo';
import Intro from '../components/Intro';
import Stats from '../components/Stats';
import Prices from '../components/Prices';
import Contact from '../components/Contact';
import Partners from '../components/partners';

import '../components/styles.less';

import iconVitamines from '../images/bg-icon-vitamines.svg';
import iconCaffeine from '../images/icone-bg-caffeine.svg';
import iconEnergy from '../images/icone-bg-energy.svg';

import gradientCaffeine from '../images/gradient-cafeine.svg';
import gradientEnergy from '../images/gradient-energy.svg';
import gradientVitamines from '../images/gradient-vitamines.svg';

import dropsBottom from '../images/drops_bottom.svg';
import swissmade from '../images/swiss-made.png';


const IndexPage = () => (
	<>
		<Layout>
			<SEO
				lang="FR"
				title="🚀 Landings Pages"
				description="Geneve.site est la nouvelle plateforme de visibilté pour les entreprises Genevoises. Créez votre landing page dès maintenant !"
			/>

			<Helmet>
				<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8197254.js"/>
			</Helmet>

			<Intro/>

			<Prices/>

			{/*<LastPromo/>

			<div className="separator" style={{ marginTop: 80 }}/>

			<Partners/>

			<div className="separator" style={{ marginTop: 80 }}/>*/}

			<Contact/>


			<img src={gradientCaffeine} className={'gradient-caffeine'}/>
			<img src={gradientEnergy} className={'gradient-energy'}/>
			<img src={gradientVitamines} className={'gradient-vitamines'}/>
			<img src={gradientCaffeine} className={'gradient-caffeine2'}/>

			{/*}
			<img src={iconVitamines} className={'icone-bg-vitamins'}/>
			<img src={iconCaffeine} className={'icone-bg-caffeine'}/>
			<img src={iconEnergy} className={'icone-bg-energy'}/>
			*/}


			<img src={dropsBottom} className={'drops-svg-bottom'}/>
		</Layout>
	</>
);

export default IndexPage;




